import {log} from '@shelf/client-logger';
import i18n from '@shelf/i18n-ssp';
import type i18next from '@shelf/i18n-ssp';
import type {LanguageCode} from '@shelf/types/lib/l10n';
import {isBrowser} from '../helpers/browserHelpers';
import debugLib from '../debug';

const debug = debugLib('Error');

export const useServerLanguage = (lng?: LanguageCode): typeof i18next => {
  if (!lng) {
    debug('no language passed');
  }

  return i18n.cloneInstance(getDefaultConfigOptions(lng || 'en'));
};

export const getDefaultLanguage = () => {
  return i18n.getFixedT('en');
};

export {i18n};

export default i18n;

function getDefaultConfigOptions(lng: LanguageCode) {
  return {
    lng,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    parseMissingKeyHandler: (key: string) => {
      const message = `MISSING TRANSLATION for key: "${key}" in "${lng}"`;

      debug(message);
      if (isBrowser()) {
        log.error(message);
      }

      return '';
    },
  };
}
