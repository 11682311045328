import React from 'react';

type ChildElementsToModify = {
  className: string;
  classNamesOnHover: string;
};

type Props = {
  childElementsToModify?: ChildElementsToModify[];
  TagName: keyof JSX.IntrinsicElements;
  classNamesOnHover?: string;
};

enum EventType {
  add = 'add',
  remove = 'remove',
}

export const hoverableWrapperDataTestId = 'hoverableWrapperDataTestId';

const HoverableWrapper: React.FunctionComponent<
  Props & React.HTMLAttributes<HTMLOrSVGElement>
> = props => {
  const {childElementsToModify = [], TagName, classNamesOnHover, ...rest} = props;

  const Wrapper = TagName;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <Wrapper
      data-testid={hoverableWrapperDataTestId}
      {...rest}
      onMouseEnter={(event: React.MouseEvent) => {
        onMouseEvent({event, eventType: EventType.add, childElementsToModify, classNamesOnHover});
      }}
      onMouseLeave={(event: React.MouseEvent) => {
        onMouseEvent({
          event,
          eventType: EventType.remove,
          childElementsToModify,
          classNamesOnHover,
        });
      }}
    />
  );
};

type OnMouseEventProps = {
  eventType: EventType;
  childElementsToModify: ChildElementsToModify[];
  classNamesOnHover?: string;
  event: React.MouseEvent;
};

function onMouseEvent(props: OnMouseEventProps) {
  const {eventType, childElementsToModify, classNamesOnHover, event} = props;

  const currentElement = event.currentTarget;

  if (classNamesOnHover) {
    currentElement.classList[eventType](classNamesOnHover);
  }
  childElementsToModify.forEach(child => {
    currentElement.querySelector(child.className)?.classList[eventType](child.classNamesOnHover);
  });
}

export default HoverableWrapper;
