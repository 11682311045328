import type {ReduxState} from '../types';
import {actionTypes} from './types';
import {staticInitialState} from './staticInitialState';

export const reducer = (state = staticInitialState, action: any): ReduxState => {
  switch (action.type) {
    case actionTypes.TOGGLE_SIDEBAR:
      return {...state, sidebarMobileVisible: action.isOpen};
    case actionTypes.SEARCH_BY_TERM: {
      return {...state, term: action.term, page: action.page, loading: action.searchLoading};
    }
    case actionTypes.SET_ANON_USER_ID: {
      return {...state, anonUserId: action.currentAnonUserId};
    }

    case actionTypes.SET_APP_IN_PREVIEW_MODE: {
      return {...state, isAppInPreviewMode: action.isAppInPreviewMode};
    }
    case actionTypes.SET_SSP_PAGE_VIEWED_EVENT_ID: {
      return {...state, sspPageViewedEventId: action.sspPageViewedEventId};
    }
    default:
      return state;
  }
};
