import React, {useEffect, useRef, useState} from 'react';

export type ElementWithClickStateProps = {
  children: JSX.Element;
  isManualToggle?: boolean;
  autoToggleTimerMs?: number;
};

const ElementWithClickStateProvider = ({
  children,
  autoToggleTimerMs,
  isManualToggle,
}: ElementWithClickStateProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const timerRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    return () => clearTimeout(timerRef?.current);
  }, []);

  const handleClick = () => {
    if (isManualToggle) {
      setIsClicked(prev => !prev);

      return;
    }

    if (isClicked && !isManualToggle) return;

    setIsClicked(true);

    if (autoToggleTimerMs) {
      timerRef.current = setTimeout(() => {
        setIsClicked(false);
      }, autoToggleTimerMs);
    }
  };

  const renderChildren = () => {
    return React.Children.map(children, (child, i) => {
      if (!child) return null;

      return React.cloneElement(child, {
        key: i,
        onClick: handleClick,
        isClicked,
      });
    });
  };

  return <React.Fragment>{renderChildren() || null}</React.Fragment>;
};

export default ElementWithClickStateProvider;
