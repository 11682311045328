import React, {memo, useEffect, useState} from 'react';
import dynamic from 'next/dynamic';
import JsxParser from '@shelf/react-jsx-parser';
import {log} from '@shelf/client-logger';
import {createGlobalStyle} from 'styled-components';
import {ClickOutsideProvider} from '@shelf/react-outside-click';
import {isEqual} from 'lodash-es';
import type {TemplateDefinition} from '@shelf/types/lib/api/self-service';
import type {TProps as JSXParserProps} from '@shelf/react-jsx-parser/lib/parserHelpers';
import type {ApplicationContext} from './types';
import I18NT from '../components/I18nT';
import SharePageUrl from '../components/SharePageUrl';
import Searchbar from '../components/Searchbar';
import Preloader from '../components/Preloader';
import HTMLComment from '../components/HTMLComment';
import PrintButton from '../components/PrintButton';
import ColoredIcon from '../components/ColoredIcon';
import Header from '../components/Header';
import NewestArticlesProvider from '../components/NewestArticlesProvider';
import ExternalLibs from '../components/ExternalLibs';
import HoverableWrapper from '../components/HoverableWrapper';
import DebugData from '../components/DebugData';
import SearchAutoSuggestProvider from '../components/SearchAutoSuggestProvider';
import ElementWithClickStateProvider from '../components/ElementWithClickStateProvider';
import {isBrowser} from './helpers/browserHelpers';

const MostPopularCategoriesProvider = dynamic(
  () => import('../components/MostPopularCategoriesProvider'),
  {
    ssr: false,
  }
);

const MostPopularTagsProvider = dynamic(() => import('../components/MostPopularTagsProvider'), {
  ssr: false,
});

const RecommendedArticlesProvider = dynamic(
  () => import('../components/RecommendedArticlesProvider'),
  {
    ssr: false,
  }
);

const MostPopularArticlesProvider = dynamic(
  () => import('../components/MostPopularArticlesProvider'),
  {
    ssr: false,
  }
);

const LinksPageNavigation = dynamic(() => import('../components/LinksPageNavigation'), {
  ssr: false,
});

const FeedbackSnippet = dynamic(() => import('../components/FeedbackSnippet'), {
  ssr: false,
});

const MetaSection = dynamic(() => import('../components/MetaSection'), {
  ssr: false,
});

const DecisionTreeView = dynamic(() => import('../components/DecisionTreeView'), {
  ssr: false,
});

const CollapsibleGemItem = dynamic(() => import('../components/CollapsibleGemItem'), {
  ssr: false,
});

const CustomizableFeedback = dynamic(() => import('../components/CustomizableFeedback'), {
  ssr: false,
});

const ArticlesTree = dynamic(() => import('../components/ArticlesTree'), {
  ssr: false,
});

const SearchPagination = dynamic(() => import('../components/SearchPagination'), {
  ssr: false,
});

const Tooltip = dynamic(() => import('../components/Tooltip'), {
  ssr: false,
});

const SectionLabel = dynamic(() => import('../components/SectionLabel'), {
  ssr: false,
  loading: () => null,
});

const internalComponents = {
  ColoredIcon,
  Header,
  ClickOutsideProvider,
  HTMLComment,
  I18NT,
  Tooltip,
};

const GlobalStyle = React.memo<{cssDefinition: string}>(props => {
  const {cssDefinition} = props;

  const Component = createGlobalStyle`
  ${cssDefinition}
`;

  return <Component />;
});

const JSXComponentsParserNoMemo: React.FC<{
  htmlString: string;
  bindings: Record<string, any>;
  templateDefinition?: Partial<TemplateDefinition>;
}> = ({htmlString, bindings, templateDefinition}) => {
  const [parsingError, setParsingError] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setParsingError('');
    }, 3000);
  }, [parsingError]);

  const apiComponentDefinitions = templateDefinition?.componentDefinitions
    ? getComponentsFromDefinitions(templateDefinition?.componentDefinitions, bindings)
    : {};

  const components: JSXParserProps['components'] = {
    ...apiComponentDefinitions,
    ...internalComponents,
    ArticlesTree,
    ExternalLibs,
    FeedbackSnippet,
    HoverableWrapper,
    MetaSection,
    SearchPagination,
    Searchbar,
    SearchAutoSuggestProvider,
    CollapsibleGemItem,
    SectionLabel,
    SharePageUrl,
    Preloader,
    MostPopularArticlesProvider,
    MostPopularCategoriesProvider,
    MostPopularTagsProvider,
    NewestArticlesProvider,
    RecommendedArticlesProvider,
    LinksPageNavigation,
    PrintButton,
    DebugData,
    DecisionTreeView,
    ElementWithClickStateProvider,
    CustomizableFeedback,
  };

  return (
    <>
      {/* Notify user about some syntax errors from their code */}
      {parsingError ? <pre>{parsingError}</pre> : null}
      <JsxParser
        jsx={htmlString}
        bindings={bindings}
        components={components}
        renderInWrapper={false}
        autoCloseVoidElements={true}
        onError={error => {
          setParsingError(error.toString());
          if (isBrowser()) {
            log.warn(error.toString(), {error});
          }
        }}
        renderUnrecognized={tagName => {
          if (isBrowser()) {
            log.warn('renderUnrecognized', {tagName});
          }

          return null;
        }}
      />
      {/* In this way all components are prefixed and happy */}
      {templateDefinition?.cssDefinition ? (
        <GlobalStyle cssDefinition={templateDefinition?.cssDefinition} />
      ) : null}
    </>
  );
};

export const JSXComponentsParser = memo(JSXComponentsParserNoMemo, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);

function getComponentsFromDefinitions(
  componentDefinitions: TemplateDefinition['componentDefinitions'],
  bindings: JSXParserProps['bindings']
): Record<string, (props: Record<string, any>) => React.ReactNode> {
  return componentDefinitions
    .filter(({jsxDefinition}) => Boolean(jsxDefinition))
    .map(({name, jsxDefinition, cssDefinition}) => {
      return {
        [name]: (props: Record<string, any>) => {
          return (
            <JSXComponentsParser
              htmlString={jsxDefinition || ''}
              bindings={{...bindings, ...props}}
              templateDefinition={{
                cssDefinition: cssDefinition,
                componentDefinitions: componentDefinitions,
              }}
            />
          );
        },
      };
    })
    .reduce((prevValue, currentValue) => {
      return {...prevValue, ...currentValue};
    }, {});
}

export const getJSXRouteDefinition = (context: ApplicationContext): string => {
  return (context.query?.templateDefinition?.routeDefinitions[0] || {}).jsxDefinition || '';
};

export const getJSTemplateDefinition = (context: ApplicationContext): string => {
  return context.query?.templateDefinition?.jsDefinition || '';
};

export const getCssRouteDefinition = (context: ApplicationContext): string => {
  return (context.query?.templateDefinition?.routeDefinitions[0] || {}).cssDefinition || '';
};
