import React from 'react';
import {log} from '@shelf/client-logger';
import type {ErrorInfo} from 'react';

export default class ErrorBoundaryApp extends React.Component<any> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log.error('ErrorBoundary: Client side error occurred', {error, errorInfo});
  }

  render() {
    return this.props.children;
  }
}
