import type {ArticlePageDTCurrentGem} from '../types';
import type {ArticleDTPageRoutesData, GetDataForRouteArgs} from './types';
import {getCommonDataForGemPagesRoutes} from './common';
import {getBreadcrumbsForUI} from './utils';

function getDataForArticleDTPageRoute(args: GetDataForRouteArgs): ArticleDTPageRoutesData {
  const currentGem = args.query.currentGem as ArticlePageDTCurrentGem;

  const breadCrumbs = getBreadcrumbsForUI(currentGem.breadCrumbs || []);

  return {
    ...getCommonDataForGemPagesRoutes(args),
    breadCrumbs,
  } as ArticleDTPageRoutesData;
}

export default getDataForArticleDTPageRoute;
