import {useEffect, useRef, useState} from 'react';

type Args = {
  toastDuration: number;
};

const useAnimation = ({
  toastDuration,
}: Args): {
  shouldRenderToast: boolean;
  animateToastToHide: boolean;
  hideToast: () => void;
  showToast: () => void;
} => {
  const [shouldRenderToast, setShouldRenderToast] = useState(false);
  const [animateToastToHide, setAnimateToastToHide] = useState(false);
  const timeoutsRef = useRef<{
    rendering: NodeJS.Timeout[];
    hiding: NodeJS.Timeout[];
  }>({
    rendering: [],
    hiding: [],
  });

  useEffect(() => {
    clearTimeOuts(timeoutsRef.current.rendering);
    clearTimeOuts(timeoutsRef.current.hiding);

    if (shouldRenderToast) {
      setAnimateToastToHide(false);
      timeoutsRef.current.rendering.push(
        setTimeout(() => {
          setAnimateToastToHide(true);
        }, toastDuration)
      );
    }
  }, [shouldRenderToast, toastDuration]);

  useEffect(() => {
    clearTimeOuts(timeoutsRef.current.rendering);
    clearTimeOuts(timeoutsRef.current.hiding);
    if (animateToastToHide) {
      timeoutsRef.current.hiding.push(
        setTimeout(() => {
          setAnimateToastToHide(false);
          setShouldRenderToast(false);
        }, 500)
      );
    }
  }, [animateToastToHide]);

  return {
    shouldRenderToast,
    animateToastToHide,
    hideToast: () => setAnimateToastToHide(true),
    showToast: () => setShouldRenderToast(true),
  };
};

function clearTimeOuts(ids: NodeJS.Timeout[]) {
  ids.forEach(id => {
    clearTimeout(id);
  });
}

export default useAnimation;
