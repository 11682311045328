import React from 'react';
import type {FC} from 'react';

export const dataTestId = 'print-button';

type Props = {
  className?: string;
  buttonTitle?: string;
  children: React.ReactNode;
};

const PrintButton: FC<Props> = props => {
  const {className, children, buttonTitle} = props;

  return (
    <button
      className={className}
      onClick={() => window.print()}
      title={buttonTitle}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
};

export default PrintButton;
