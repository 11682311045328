import useSWR from 'swr';
import {SSPApi} from '@shelf/data-hooks';
import {useContext, useMemo, useState} from 'react';
import {debounce} from 'lodash-es';
import type {SWRConfiguration} from 'swr';
import {AppContext} from '../../lib/context';

const MAX_RESULTS = 20;

export type SearchAPIParams = Parameters<typeof SSPApi.search>[0];
export const getSearchKey = (args: SearchAPIParams) => ({api: 'SSPApi.search', args});

const fetcher = (args: ReturnType<typeof getSearchKey> | null) => {
  if (!args?.args?.term) return null;

  return SSPApi.search(args.args);
};

export const useSearch = (args: SearchAPIParams, options?: SWRConfiguration) => {
  const {data, ...rest} = useSWR(getSearchKey(args), fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: true,
    ...options,
  });

  const mappedData = useMemo(() => {
    if (!data) return data;

    return {
      ...data,
      items: data.items.slice(0, MAX_RESULTS),
    };
  }, [data]);

  return {data: mappedData, ...rest};
};

export const useAutosuggestData = (searchParams?: Partial<SearchAPIParams>) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const context = useContext(AppContext);

  const lang = context?.query?.appInfo?.settings?.language || 'en';

  const {data, ...rest} = useSearch({
    ...searchParams,
    accountId: context?.query?.appInfo?.accountId || '',
    libId: context?.query?.appInfo?.libraryId || '',
    lang,
    term: debouncedTerm?.trim(),
    attachUseReferrerToRedirectURL: true,
  });

  const debouncedSetTerm = useMemo(
    () => debounce(value => setDebouncedTerm(value), 500),
    [setDebouncedTerm]
  );

  const handleChange = (value: string) => {
    setInputValue(value);
    debouncedSetTerm(value);
  };

  const handleClear = () => {
    setInputValue('');
    setDebouncedTerm('');
  };

  const isRequestChanging = debouncedTerm !== inputValue || rest.isValidating;

  const isNoResults = Boolean(data?.items && data.items.length === 0 && !isRequestChanging);

  return {
    handleChange,
    handleClear,
    term: inputValue,
    isNoResults,
    data,
    lang,
    ...rest,
  };
};
