/* eslint-disable react/display-name */
import React from 'react';
import {createGlobalStyle} from 'styled-components';
import type {FC} from 'react';
import type {TemplateDefinition} from '@shelf/types/lib/api/self-service';
import {JSXComponentsParser} from '../../lib/replaceWithSSPComponents';
import {getPortalSettings, getRouteData} from '../../lib/helpers/routeBindings';

export type Props = {
  templateDefinition?: TemplateDefinition;
  bindings: Record<string, any>;
  htmlString: string;
  cssString?: string;
};

const GlobalStyle = React.memo<{cssDefinition: string}>(props => {
  const {cssDefinition} = props;

  const Component = createGlobalStyle`
  ${cssDefinition}
`;

  return <Component />;
});

const JSXRouteParser: FC<Props> = props => {
  const {htmlString, bindings, cssString, templateDefinition} = props;

  const PortalSettings = getPortalSettings(bindings);
  const RouteData = getRouteData(bindings);

  return (
    <>
      {templateDefinition ? (
        <JSXComponentsParser
          htmlString={htmlString}
          bindings={{
            ...bindings,
            PortalSettings,
            RouteData,
          }}
          templateDefinition={templateDefinition}
        />
      ) : null}
      {cssString ? <GlobalStyle cssDefinition={cssString} /> : null}
    </>
  );
};

export default JSXRouteParser;
