import React from 'react';
import ReactDOM from 'react-dom';
import type {ComponentProps} from 'react';
import ColoredIcon from '../ColoredIcon';
import styles from './SharePageUrl.module.scss';
import {useAnimation} from './hooks';

type Props = {
  CopyElement?: JSX.Element;
  CopyToastLabel?: string;
  copyToastLabelClassName?: string;
  isClosable?: boolean;
  ShareElement?: JSX.Element;
  shareText?: string;
  shareTitle?: string;
  shareButtonTitle?: string;
  copyButtonTitle?: string;
  ToastContent?: JSX.Element;
  toastDuration?: number;
};

export const copyButtonTestId = 'copyButtonTestId';
export const shareButtonTestId = 'shareButtonTestId';

const SharePageUrl = (props: Props): JSX.Element => {
  const {
    ShareElement = (
      <ColoredIcon
        data-testid={shareButtonTestId}
        {...getIconProps({icon: 'share', holderSize: 26, iconSize: 20})}
      />
    ),
    CopyElement = (
      <ColoredIcon {...getIconProps({icon: 'clipboard', holderSize: 26, iconSize: 20})} />
    ),
    ToastContent = (
      <>
        <div className={styles.checkHolder}>
          <ColoredIcon {...getIconProps({icon: 'check', color: '#fff'})} />
        </div>
        <span className={`${styles.label} ${props.copyToastLabelClassName}`}>
          {props.CopyToastLabel}
        </span>
      </>
    ),
    isClosable = true,
    shareText,
    shareTitle,
    toastDuration = 4000,
    shareButtonTitle,
    copyButtonTitle,
  } = props;

  const {shouldRenderToast, animateToastToHide, hideToast, showToast} = useAnimation({
    toastDuration,
  });

  const handleCopyClick = () => {
    navigator.clipboard.writeText(getCurrentPageUrl());
    showToast();
  };

  const handleShareClick = () => {
    const shareData = {
      title: shareTitle,
      text: shareText,
      url: getCurrentPageUrl(),
    };
    navigator.share(shareData);
  };

  const shareOptions = {
    Element: (
      <button
        data-testid={shareButtonTestId}
        title={shareButtonTitle}
        className={styles.iconButton}
      >
        {ShareElement}
      </button>
    ),
    clickHandler: handleShareClick,
  };

  const copyOptions = {
    Element: (
      <button data-testid={copyButtonTestId} title={copyButtonTitle} className={styles.iconButton}>
        {CopyElement}
      </button>
    ),
    clickHandler: handleCopyClick,
  };

  const isShareAvailable = typeof navigator === 'object' && Boolean(navigator.share);
  const isCopyAvailable = typeof navigator === 'object' && Boolean(navigator?.clipboard?.writeText);

  const noopOptions = {
    Element: <div />,
    clickHandler: () => {},
  };

  const triggerOptions = isShareAvailable
    ? shareOptions
    : isCopyAvailable
      ? copyOptions
      : noopOptions;

  const Trigger = React.cloneElement(triggerOptions.Element, {
    onClick: triggerOptions.clickHandler,
  });

  const renderToast = () => {
    return (
      <div
        className={`${styles.SharePageUrl} ${animateToastToHide && styles['SharePageUrl--hidden']}`}
      >
        <div className={styles.container}>
          <div className={styles.body}>
            {ToastContent}
            {isClosable && (
              <div className={styles.crossHolder}>
                <ColoredIcon
                  {...getIconProps({
                    icon: 'cross',
                    color: '#ddd',
                    holderSize: 30,
                    hoverColor: '#fd3229',
                    iconSize: 12,
                    onClick: () => hideToast(),
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderToastInPortal = () => ReactDOM.createPortal(renderToast(), document.body);

  return (
    <>
      {Trigger}
      {shouldRenderToast && renderToastInPortal()}
    </>
  );
};

function getCurrentPageUrl() {
  return window.location.href;
}

function getIconProps(props: ComponentProps<typeof ColoredIcon>) {
  const {
    icon,
    holderStyles = {
      cursor: 'pointer',
    },
    holderSize = 48,
    iconSize = 18,
    holderColor = 'transparent',
    ...rest
  } = props;

  return {
    holderStyles,
    holderSize,
    iconSize,
    holderColor,
    icon,
    ...rest,
  };
}

export default SharePageUrl;
