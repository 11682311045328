import {$paleBlue} from '@shelf/colors';
import React, {useRef, useState} from 'react';
import {useRouter} from 'next/router';
import {b, createBlock} from '@shelf/bem';
import styled from 'styled-components';
import {ClickOutsideProvider} from '@shelf/react-outside-click';
import type {LanguageCode} from '@shelf/types/lib/l10n';
import type {FC, SyntheticEvent} from 'react';
import type {TToggleSideBar} from '../../lib/types';
import ColoredIcon from '../ColoredIcon';
import {slugToUrl} from '../../lib/helpers/linkHelpers';
import {SEARCH_QUERY_MAX_LENGTH} from '../../lib/constants';

const SearchInputSmall = styled.input`
  max-width: 600px;
  outline: none;
  border-bottom: 1px solid rgb(96, 109, 132);
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  height: 18px;
  line-height: 18px;
  width: 100%;
  will-change: width;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 1px 20px 1px 0;

  @media (width <= 768px) {
    max-width: 100%;
  }

  &:placeholder-shown,
  &::placeholder {
    color: rgb(42, 48, 59);
    opacity: 1;
    font-family: inherit;
  }
`;
const searchHolder = createBlock('SearchHolder');
const search = createBlock('Search');
const magnifyingLabel = createBlock('MagnifyingLabel');
const searchInputHolder = createBlock('SearchInputHolder');
const crossIcon = createBlock('CrossIcon');
export const sspSecondarySearchId = 'ssp-secondary-search';

type SearchbarProps = {
  headerTextColor: string;
  toggleSideBar?: TToggleSideBar;
  term?: string;
  pathPrefix: string;
  placeholder: string;
  small?: boolean;
  lang: LanguageCode;
};

const Searchbar: FC<SearchbarProps> = props => {
  const router = useRouter();
  const [term, setTerm] = useState('');

  const {pathPrefix, lang, headerTextColor, small, toggleSideBar, placeholder} = props;

  const [searchActive, setSearchActive] = useState(false);
  const [showCrossIcon, setShowCrossIcon] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const startSearch = (e: SyntheticEvent) => {
    e.preventDefault();

    if (term) {
      router.push({
        pathname: slugToUrl('/search/', pathPrefix, lang),
        query: {term, page: 1},
      });
    }
  };

  const queryTerm = router?.query?.term;

  const applicableTerm = queryTerm && typeof term === 'undefined' ? queryTerm : term;
  const areSearchActive = searchActive || applicableTerm;

  return (
    <form className={b(searchHolder, '', [small && 'small'])} onSubmit={startSearch}>
      <label
        className={b(search, '', [areSearchActive && 'searchActive', small && 'small'])}
        role={'search'}
        htmlFor={sspSecondarySearchId}
      >
        <span
          style={{
            visibility: 'hidden',
            pointerEvents: 'none',
            position: 'absolute',
          }}
        >
          {placeholder}
        </span>
        <span
          className={b(magnifyingLabel, '', [small && 'small'])}
          onClick={() => {
            if (searchInputRef.current) {
              searchInputRef.current.focus();
              setSearchActive(true);
            }
          }}
        >
          <ColoredIcon
            color={small ? $paleBlue : headerTextColor}
            icon={'search'}
            iconSize={small ? 10 : 14}
            holderSize={small ? 20 : 30}
          />
        </span>
        <div className={b(searchInputHolder, '', [areSearchActive && 'searchActive'])}>
          <ClickOutsideProvider
            onOutsideClick={() => {
              setSearchActive(false);
              setShowCrossIcon(false);
            }}
          >
            <SearchInputSmall
              ref={searchInputRef}
              value={applicableTerm || ''}
              onFocus={(event: SyntheticEvent) => {
                event.preventDefault();
                setSearchActive(true);
                setShowCrossIcon(true);
                if (typeof toggleSideBar === 'function') {
                  toggleSideBar(false);
                }
              }}
              onChange={e => {
                e.preventDefault();
                setTerm(e.target.value);
                setSearchActive(true);
              }}
              type="text"
              maxLength={SEARCH_QUERY_MAX_LENGTH}
              id={sspSecondarySearchId}
              data-testid={sspSecondarySearchId}
              placeholder={placeholder}
            />
          </ClickOutsideProvider>
        </div>
        <span
          className={b(crossIcon, '', [
            small && 'small',
            showCrossIcon && 'showCrossIcon',
            areSearchActive && 'searchActive',
          ])}
          onClick={(event: SyntheticEvent) => {
            event.preventDefault();
            event.stopPropagation();
            setTerm('');
            setSearchActive(false);
          }}
        >
          <ColoredIcon
            icon={'cross'}
            holderSize={30}
            iconSize={7}
            color={small ? 'rgb(42, 48, 59)' : headerTextColor}
          />
        </span>
      </label>
    </form>
  );
};

export default Searchbar;
