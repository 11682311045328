// eslint-disable-next-line no-restricted-imports
import {v4 as uuid4} from 'uuid';
import {orderBy} from '@shelf/fast-natural-order-by';
import {escape, findKey, sortBy, truncate} from 'lodash-es';
import striptags from 'striptags';
import {SSPApi} from '@sdk';
import {datadogLogs} from '@shelf/client-logger';
import {getLanguageByCode} from '@shelf/l10n';
import {parseQueryParams, stringifyQueryParams} from '@shelf/client-helpers';
import type {NextPageContext} from 'next';
import type {NextParsedUrlQuery} from 'next/dist/server/request-meta';
import type {LanguageCode} from '@shelf/types/lib/l10n';
import type {
  ApiPageResponse,
  Category,
  CategoryV2,
  CreateJourneySSPResponse,
  FolderArticle,
  FolderWidget,
  PageFolderWithUrl,
  PageTypeRecord,
  PageTypeString,
  Query,
} from '../types';
import debugLib from '../debug';
import {GemTypes} from '../types';
import {CATEGORIES_DELIMITER_UI} from '../constants';
import {slugToUrl} from './linkHelpers';
import {isBrowser} from './browserHelpers';

type HTTPErrorDefinition = {
  code: any;
  status: number;
  message: string;
};

export type GetPageInfoArgs = {
  libraryId: string;
  url: string;
  ctx: NextPageContext;
  referer?: string;
  gemId?: string;
  lang?: string;
  query?: NextParsedUrlQuery;
};

const debug = debugLib('helpers');

const DECISION_TREE_TYPE = 'Decision Tree';

// Strange: With export const requireActual works incorrectly the function is undefined in SearchPagination.test.tsx
export function uniq<T>(arr: T[]) {
  return [...new Set(arr)];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function omitSingleProp<T>(key: string, {[key]: _, ...obj}: Record<string, any>): T {
  return obj as T;
}

export function stripDescription(html: string): string {
  // nosemgrep
  return truncate(striptags(html), {length: 160});
}

export function getTitleString(baseTitle: string, pageTitle: string | undefined | null): string {
  return `${pageTitle ? `${pageTitle} | ` : ''}${baseTitle}`;
}

export function addClass(ref: React.RefObject<HTMLSpanElement>) {
  if (ref.current) {
    ref.current.className = `${ref.current.className} ${
      ref.current.className.split(' ')[0]
    }--searchActive`;
  }
}
type getPageTypeArgs = {
  url: string;
  currentGemType?: GemTypes;
};
export function getPageType(args: getPageTypeArgs): PageTypeRecord {
  const {url, currentGemType} = args;

  const isLibrary = currentGemType === GemTypes.SSL;
  const isFolder = currentGemType === GemTypes.Directory;
  const isArticle = currentGemType === GemTypes.Note;
  const isDecisionTree = currentGemType === GemTypes.DecisionTree;
  const isSearch = url.includes('/search/') || url === '/search';
  const defaultResponse = {
    root: false,
    page: false,
    pageDT: false,
    folder: false,
    search: false,
    404: false,
  };

  switch (true) {
    case isLibrary:
      return {...defaultResponse, root: true};
    case isSearch:
      return {...defaultResponse, search: true};
    case isFolder:
      return {...defaultResponse, folder: true};
    case isArticle:
      return {...defaultResponse, page: true};
    case isDecisionTree:
      return {...defaultResponse, pageDT: true};
    default:
      return {...defaultResponse, 404: true};
  }
}

export function getPageTypeString(pageType: PageTypeRecord): PageTypeString {
  const activePageKey = findKey(pageType, val => val === true);

  switch (activePageKey) {
    case 'root':
      return 'home';
    case 'page':
      return 'article';
    case 'pageDT':
      return 'article-dt';
    case 'folder':
      return 'folder';
    case 'search':
      return 'search';
    default:
      return '404';
  }
}

export function getSSRPageInfo(args: GetPageInfoArgs): Promise<ApiPageResponse | null> | null {
  const {libraryId, url, gemId, lang, query, ctx} = args;
  const queryString = query ? `?${stringifyQueryParams(query)}` : '';

  if (!libraryId) {
    return null;
  }

  return SSPApi.getPage({
    libraryId,
    slugUrl: url,
    queryString,
    gemId,
    lang,
    sspApiKey: process.env.SSP_X_API_KEY || '',
    attachUseReferrerToRedirectURL: true,
  }).catch(response => {
    Object.assign(ctx, {getPageAPIError: response.error ?? response});

    return null;
  });
}

type CreateDTJourneyReturnValue = CreateJourneySSPResponse & {
  anonUserId: string;
};

export async function createDTJourney({
  accountId,
  libraryId,
  lang,
  gemId,
  initialStepId,
  apiKey,
  gemType,
  searchQuery,
}: {
  accountId: string;
  libraryId: string;
  lang: LanguageCode;
  gemId: string;
  apiKey: string;
  gemType?: string;
  initialStepId?: string;
  searchQuery?: string;
}): Promise<CreateDTJourneyReturnValue | undefined> {
  if (gemType !== DECISION_TREE_TYPE) {
    return;
  }

  const emptyJourney = {
    initialStep: {},
    journeyId: '',
    firstStepId: '',
    isOpenDTLinkInNewTabEnabled: false,
    anonUserId: '',
  };
  const anonUserId = `anon_${uuid4()}`;

  try {
    const journey = await SSPApi.createDTJourney({
      accountId,
      libraryId,
      lang,
      gemId,
      anonUserId,
      apiKey,
      initialStepId,
      searchQuery,
    });

    return {
      ...journey,
      anonUserId,
    };
  } catch {
    return emptyJourney as CreateDTJourneyReturnValue;
  }
}

export function trackPageView(pageProps: any, anonUserId: string): Promise<unknown> {
  if (typeof window !== 'undefined' && pageProps) {
    const appInfo = pageProps.query?.appInfo || {};

    const {libraryId: libId, accountId} = appInfo;
    const gemId = pageProps.query?.currentGem?._id;
    const url = window.location.href;
    debug('trackPageView', pageProps);

    if (!accountId || !libId) {
      return Promise.resolve();
    }

    return SSPApi.trackActivity({
      libId,
      accountId,
      activity: {
        actions: [
          {
            name: 'ssp.page-view',
            payload: {anonUserId, url, ...(gemId ? {gemId} : {})},
          },
        ],
      },
    });
  }

  return Promise.resolve();
}

export function giveUserFeedback({
  gemId,
  kind,
  anonUserId,
  libraryId,
  reason,
  text,
}: {
  gemId: string;
  kind: 'upvote' | 'downvote';
  libraryId: string;
  anonUserId?: string;
  reason?: string;
  text?: string;
}) {
  if (isBrowser() && gemId) {
    if (!kind) {
      return;
    }

    return SSPApi.giveFeedback({
      gemId,
      kind,
      anonUserId,
      libraryId,
      ...(reason && {reason}),
      ...(text?.trim() && {text: escape(text)}),
    });
  }
}

type getFoldersForWidgetArgs = {
  folders: PageFolderWithUrl[];
};

export function getFoldersForWidget(args: getFoldersForWidgetArgs): FolderWidget[] {
  const {folders} = args;

  const addUrlToGem = (item: FolderArticle) => ({
    ...item,
    url: item.slug,
  });

  const addChildrenGems = (folder: PageFolderWithUrl) => ({
    ...folder,
    children: folder.gems.map(addUrlToGem),
  });

  return folders.map(addChildrenGems);
}

export function getCustomDomainFromQuery(query?: Pick<Query, 'appInfo'>): string | false {
  return query?.appInfo?.settings?.customDomain || false;
}

export function getPathPrefixFromDomain(customDomain?: string | false | null): string {
  if (customDomain && customDomain.includes('/')) {
    try {
      const customUrl = new URL(
        customDomain.startsWith('http://') || customDomain.startsWith('https://')
          ? customDomain
          : `https://${customDomain}`
      );

      if (customUrl.host && customUrl.pathname) {
        if ((customUrl.pathname || '').endsWith('/')) {
          return customUrl.pathname.substring(0, customUrl.pathname.length - 1);
        }

        return customUrl.pathname;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error in url parsing', {e, customDomain});

      return '';
    }
  }

  return '';
}

export function getURLParams(): {
  term?: string;
  page?: string;
  tag?: string;
  category?: string;
  categoryId?: string;
} {
  const {
    term,
    page,
    tag,
    category,
    categoryId,
  }: {
    term?: string;
    page?: string;
    tag?: string;
    category?: string;
    categoryId?: string;
  } = parseQueryParams(window.location.search.substr(1));

  return {
    term,
    page,
    tag: tag ? tag : undefined,
    category: category ? category : undefined,
    categoryId: categoryId ? categoryId : undefined,
  };
}

export async function getPageInfo(args: GetPageInfoArgs): Promise<ApiPageResponse | null> {
  const sspPageInfoResp = await getSSRPageInfo(args);

  //Hack to check shared vars in lambda
  process.env.SSR_PAGE_INFO = JSON.stringify({
    [args.libraryId]: sspPageInfoResp,
  });

  return sspPageInfoResp;
}

export async function guardAPIRequest<T, F>({
  condition = true,
  request,
  logMessage,
  fallback,
}: {
  condition?: boolean;
  request: () => Promise<T>;
  logMessage: string;
  fallback: F;
}): Promise<T | F> {
  try {
    if (condition && request) {
      return await request();
    }
  } catch (err: any) {
    const isErrorFromAPI = isAPIError(err.error);
    const error = isErrorFromAPI ? err.error : err;

    if (isBrowser()) {
      datadogLogs.logger[isErrorFromAPI ? selectErrorLogLevel(error.status) : 'error'](
        logMessage,
        error
      );
    }
  }

  return fallback;
}

export function headerLinkHref(link: string) {
  if (!link) {
    return '';
  }

  const linkWithProtocol = getURIFromSettings(link);

  return linkWithProtocol.href;
}

export function headerLinkLabel(link: string) {
  if (!link) {
    return '';
  }

  const linkWithProtocol = getURIFromSettings(link);

  if (linkWithProtocol.pathname === link) {
    return capitalizeAndRemoveWWW(linkWithProtocol.pathname);
  }

  return capitalizeAndRemoveWWW(linkWithProtocol.hostname);
}
export function getLanguageName(targetLanguage: LanguageCode): string {
  const extendedLanguageByCode = getLanguageByCode(targetLanguage);

  return extendedLanguageByCode?.native || targetLanguage;
}

export function replaceClass(ref: React.RefObject<HTMLSpanElement>) {
  if (ref.current) {
    ref.current.className = uniq(
      ref.current.className.replace(`--searchActive`, '').split(' ')
    ).join(' ');
  }
}

export function addProtocol(url?: string) {
  try {
    new URL(url ?? '');

    return url;
  } catch (e) {
    return `https://${url}`;
  }
}

type CategoryV2withIndex = CategoryV2 & {index: number};

export function sortMostPopularCategory(categories: CategoryV2withIndex[]): CategoryV2withIndex[] {
  return categories.reduce((acc, val) => {
    acc[val.index] = val;

    return acc;
  }, new Array(categories?.length));
}

export function sortCategoriesWithSameNameByCountAndTopParentName(
  categories: CategoryV2withIndex[]
): CategoryV2withIndex[] {
  const indexes = sortBy(categories, 'index').map(item => item.index);

  return orderBy(
    [...categories],
    [v => v.count, v => v.topParentCategoryName],
    ['desc', 'asc']
  ).map((category, idx) => ({...category, index: indexes[idx]}));
}

export function processHierarchicalCategories(categories: CategoryV2[]): CategoryV2[] {
  if (!categories || categories.length === 0) {
    return [];
  }

  const isMostPopularCategories = Object.prototype.hasOwnProperty.call(categories[0], 'count');

  const categoriesMap = categories
    .map((category, idx) => ({...category, index: idx}))
    .reduce((acc, val) => {
      if (Boolean(acc.get(val.categoryName)) === false) {
        acc.set(val.categoryName, [val]);

        return acc;
      }

      acc.set(val.categoryName, [...acc.get(val.categoryName), val]);

      return acc;
    }, new Map());

  const nestedCategories = Array.from(categoriesMap.values()).map(
    (categories: CategoryV2withIndex[]) => {
      let currentNestedCategories = [...categories];

      if (currentNestedCategories.length > 1) {
        if (!isMostPopularCategories) {
          currentNestedCategories = orderBy([...currentNestedCategories], 'topParentCategoryName');
        } else {
          currentNestedCategories =
            sortCategoriesWithSameNameByCountAndTopParentName(currentNestedCategories);
        }

        return currentNestedCategories.map(category => ({
          ...category,
          categoryLabel: category.topParentCategoryName
            ? `${category.topParentCategoryName}${CATEGORIES_DELIMITER_UI}${category.categoryName}`
            : category.categoryName,
        }));
      }

      return currentNestedCategories;
    }
  );

  const flattenCategories = nestedCategories.reduce((acc, val) => [...acc, ...val]);

  const flattenNestedCategory = isMostPopularCategories
    ? sortMostPopularCategory(flattenCategories)
    : flattenCategories;

  return flattenNestedCategory.map(category => omitSingleProp<CategoryV2>('index', category));
}

export function getMostPopularCategoriesForUI(
  categories: (Category | CategoryV2)[],
  options: {
    pathPrefix?: string;
    lang: LanguageCode;
  }
): {
  name: string;
  count: number;
  url: string;
}[] {
  const {pathPrefix, lang} = options;

  const proceededCategories = processHierarchicalCategories(categories as CategoryV2[]);

  return (
    (proceededCategories || []).map(item => {
      const searchQueryValue = item.categoryId;

      const {categoryName} = item;
      const categoryLabel = item.categoryLabel || item.categoryName;

      const searchQueryName = 'categoryId';

      return {
        count: item.count || 0,
        name: categoryLabel,
        url: `${slugToUrl('/search/', pathPrefix, lang)}?${stringifyQueryParams({
          [searchQueryName]: searchQueryValue,
          category: categoryName,
          page: 1,
        })}`,
      };
    }) || []
  );
}

function getURIFromSettings(link: string) {
  if (!link) {
    throw new Error(`Link is empty: ${link}`);
  }

  try {
    return new URL(link);
  } catch (e) {
    return new URL(`https://${link}`);
  }
}

function capitalizeAndRemoveWWW(linkToken: string) {
  return (
    linkToken.replace('www.', '').charAt(0).toUpperCase() +
    linkToken.replace('www.', '').slice(1).toLowerCase()
  );
}

export function getReferrer() {
  return isBrowser() ? document.referrer : '';
}

export function isPageLoadedInIframe() {
  if (!isBrowser()) {
    return false;
  }

  return window !== window.top;
}

type SSRError = {
  message: string;
  meta: Record<string, unknown>;
  logLevel: 'error' | 'info' | 'debug' | 'warn';
};
export function logSSRError(error: SSRError, logEmitter: any): void {
  if (isBrowser()) {
    return;
  }

  if (logEmitter) {
    logEmitter.emit('log', error);
  } else {
    // eslint-disable-next-line no-console
    console[error.logLevel]('Just Local server error ->', error.message, error.meta);
  }
}

function selectErrorLogLevel(statusCode: number): 'info' | 'error' {
  if (statusCode >= 400 && statusCode < 500) {
    return 'info';
  }

  return 'error';
}

function isAPIError(error: HTTPErrorDefinition | Error | null): boolean {
  if (!error) {
    return false;
  }

  return (
    // eslint-disable-next-line no-prototype-builtins
    ['code', 'status', 'message'].every(prop => error.hasOwnProperty(prop)) ||
    // eslint-disable-next-line no-prototype-builtins
    ['detail', 'status', 'message'].every(prop => error.hasOwnProperty(prop)) ||
    // eslint-disable-next-line no-prototype-builtins
    ['status', 'message'].every(prop => error.hasOwnProperty(prop))
  );
}
