import {pick} from 'lodash-es';
import type {AppInfo} from '../types';
import {htmlWithCollapsibleSections} from './html-with-collapsible-sections';
import {setTOCIntoText} from './set-toc-into-text';
import {getReferrer, isPageLoadedInIframe} from './helpers';

export function getRouteData(bindings: Record<string, any>) {
  const wikiText = setTOCIntoText(htmlWithCollapsibleSections(bindings.gem?.wikiText || ''));
  const documentReferrer = getReferrer();
  const isInIframe = isPageLoadedInIframe();

  const currentPageGem = {
    title: bindings.gem?.title || '',
    description: bindings.gem?.description || '',
    wikiText,
  };

  return {
    mostPopularArticles: bindings.query?.mostPopularArticles || [],
    categories: bindings.query?.categories || [],
    tags: bindings.query?.tags || [],
    topArticles: bindings.topArticles || [],
    topFoldersWithChildren: bindings.topFoldersWithChildren || [],
    lang: bindings.lang || '',
    homeHref: bindings.homeHref,
    searchPathPrefix: bindings.pathPrefix,
    sidebarMobileVisible: bindings.sidebarMobileVisible,
    multiline: bindings.multiline,
    breadCrumbs: bindings.breadCrumbs,
    showTargetTitle: bindings.showTargetTitle,
    searchInputPlaceholder: bindings.searchInputPlaceholder,
    sideSectionItemsFetching: bindings.sideSectionItemsFetching,
    searchURL: bindings.searchURL,
    staticAssetsURL: bindings.staticAssetsURL,
    footerShelfLink: 'https://shelf.io',
    searchResults: {
      items: bindings.rows || [],
      total: bindings.total,
      term: bindings.term,
      loading: bindings.loading,
      resultText: bindings.resultText,
      isPaginationShown: bindings.isPaginationShown,
      isSearchResultBreadcrumbs: bindings.isSearchResultBreadcrumbs,
    },
    // TODO: remove map after filter will implemented in parser
    tagsByCategory: bindings.query?.tagsByCategory?.map((tag: {name: string}) => tag.name) ?? [],
    searchQuery: bindings.query?.searchQuery ?? {},
    stringifiedCategory: bindings.query?.stringifiedCategory ?? '',
    currentPageGem,
    documentReferrer,
    isInIframe,
  };
}

export function getPortalSettings(bindings: Record<string, any>) {
  const settings = bindings?.query?.appInfo?.settings as AppInfo['settings'];
  const settingsPlaceholder = getPlaceholderSettingsData();

  return settings
    ? {
        publishedLanguages: bindings?.query?.appInfo.publishedLanguages || [],
        BasicSettings: {
          ...settingsPlaceholder.BasicSettings,
          language: settings.language ?? 'en',
          selfServicePortalName: settings.name,
          homePageLink: settings?.header?.homepage || '',
          ...pick(settings, [
            'homePageLink',
            'userFeedbackEnabled',
            'showArticleCategories',
            'showArticleEstimatedReadingTime',
            'showArticleTags',
            'showArticlesAuthorAndLastUpdatedDate',
            'showLanguageSelector',
            'showExtraSearch',
            'showMostViewedArticles',
          ]),
        },
        Branding: {
          images: {
            ...settingsPlaceholder.Branding.images,
            ...settings?.images,
            headerImage: settings?.header?.image,
          },
          header: {
            colors: {
              bg: settings?.header?.colors?.bg || settingsPlaceholder.Branding.header.colors.bg,
              text:
                settings?.header?.colors?.text || settingsPlaceholder.Branding.header.colors.text,
            },
          },
        },
        HomePage: {
          description: settings?.description,
          content: settings?.content,
        },
        Footer: {
          ...settingsPlaceholder.Footer,
          ...settings?.footer,
          socialLinksList: bindings.socialLinks,
          customHTML: bindings.footerCustomHTML,
          customLinks: bindings.footerLinks,
        },
      }
    : settingsPlaceholder;
}

function getPlaceholderSettingsData() {
  return {
    publishedLanguages: [],
    BasicSettings: {
      language: 'en',
      homePageLink: '',
      showLanguageSelector: false,
    },
    Branding: {
      images: {
        defaultArticleThumbnail: '',
      },
      header: {
        colors: {
          bg: 'rgb(0, 0, 70)',
          text: 'white',
        },
      },
    },
    HomePage: {},
    Footer: {
      socialLinks: {},
      socialLinksList: [],
      customLinks: [],
      customHTML: '',
    },
  };
}
