import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist';
import {createWrapper} from 'next-redux-wrapper';
import {isBrowser} from '../helpers/browserHelpers';
import {reducer} from './reducer';
import {staticInitialState} from './staticInitialState';

export const initStore = (initialState: any) => {
  if (!isBrowser()) {
    return createStore(reducer, initialState);
  }

  if (isBrowser()) {
    const isDev = process.env.ENVIRONMENT === 'dev';
    const isDevOrStaging = isDev || process.env.ENVIRONMENT === 'staging';
    // we need it only on client side

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const storage = require('redux-persist/lib/storage').default;

    const persistConfig = {
      key: 'nextjs',
      whitelist: ['anonUserId'], // make sure it does not clash with server keys
      storage,
    };

    const persistedReducer = persistReducer(persistConfig, reducer);

    const middleware = isDevOrStaging
      ? composeWithDevTools(applyMiddleware(thunkMiddleware))
      : applyMiddleware(thunkMiddleware);
    const store: any = createStore(persistedReducer, staticInitialState as any, middleware);

    store.__persistor = persistStore(store);

    return store;
  }
};

export const wrapper = createWrapper(initStore);
