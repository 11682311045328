// eslint-disable-next-line no-restricted-imports
import {v4 as uuid4} from 'uuid';
import type {ReduxState} from '../types';
import {actionTypes} from './types';

export const toggleSideBar = (isOpen: boolean) => {
  return {
    type: actionTypes.TOGGLE_SIDEBAR,
    isOpen,
  };
};

export const setAppInPreviewMode = (isAppInPreviewMode: boolean) => {
  return {
    type: actionTypes.SET_APP_IN_PREVIEW_MODE,
    isAppInPreviewMode,
  };
};

export const searchAction = (term?: string, page?: any, loading?: boolean) => {
  return {
    type: actionTypes.SEARCH_BY_TERM,
    term,
    page,
    searchLoading: loading,
  };
};

export const getAnonUserId = () => {
  return (dispatch: (params: any) => void, getState: () => ReduxState) => {
    const {anonUserId} = getState();
    let currentAnonUserId = anonUserId;

    if (!currentAnonUserId) {
      currentAnonUserId = `anon_${uuid4()}`;
      dispatch({type: actionTypes.SET_ANON_USER_ID, currentAnonUserId});
    }

    return currentAnonUserId;
  };
};

export const setSSPPageViewedEventId = (sspPageViewedEventId: string) => {
  return {
    type: actionTypes.SET_SSP_PAGE_VIEWED_EVENT_ID,
    sspPageViewedEventId,
  };
};
