import React from 'react';
import {useNewestArticles} from './hooks';

type Props = {
  children: JSX.Element;
};

const NewestArticlesProvider = (props: Props): JSX.Element => {
  const {children} = props;
  const NewestArticlesWidgetState = useNewestArticles();

  const renderChildren = () => {
    return React.Children.map(children, (child, i) => {
      return React.cloneElement(child, {
        key: i,
        loading: NewestArticlesWidgetState.isFetching,
        data: NewestArticlesWidgetState.articles,
      });
    });
  };

  return <React.Fragment>{renderChildren() || null}</React.Fragment>;
};

export default NewestArticlesProvider;
