import React from 'react';
import App from 'next/app';
import {defer} from 'lodash-es';
import {ReactReduxContext} from 'react-redux';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import {staticCDNProvider} from '@shelf/client-helpers';
import {wrapper} from '../lib/store/store';
import debugLib from '../lib/debug';
import {logSSRError, trackPageView} from '../lib/helpers/helpers';
import {isBrowser} from '../lib/helpers/browserHelpers';
import {getAnonUserId, setSSPPageViewedEventId} from '../lib/store/actions';
import {DataDogLoggers} from '../lib/DatadogLoggers';
import ErrorBoundaryApp from '../components/ErrorBoundary';
import ErrorPage from './404';
import '../styles.scss';

const debug = debugLib('App');

let isPageViewed = false;

export const withError = Component =>
  class extends React.Component {
    static async getInitialProps(context) {
      const props = await Component.getInitialProps(context);
      const {
        ctx: {res, err, getPageAPIError},
      } = context;

      const statusCode = getPageAPIError
        ? getPageAPIError.status
        : err
          ? err.statusCode
          : res
            ? res.statusCode
            : null;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {res: res_, req: req_, ...restOfCtx} = props?.initialState?.ctx ?? {};

      const initialProps = {
        ...props,
        initialState: {
          ...props.initialState,
          ctx: restOfCtx,
        },
      };

      return {
        initialProps,
        statusCode,
        err,
        getPageAPIError,
        logEmitter: res?.logEmitter,
        resError: res?.error,
      };
    }

    render() {
      const {initialProps} = this.props;
      const pageType = initialProps.pageProps?.query?.pageType ?? {};
      const is404Page = pageType['404'];

      debug('withError', {
        props: initialProps,
        appInfo: this.initialState?.serverInfo?.appInfo,
        isArticlePage: pageType.page || pageType.pageDT === true,
        is404Page,
      });

      return <Component {...this.props} show404={is404Page} />;
    }
  };

class MyApp extends App {
  logPageView(store) {
    if (isPageViewed) return;

    isPageViewed = true;

    const {dispatch, getState} = store;
    const state = getState();

    const isReady = state?._persist?.rehydrated;
    const {pageProps} = this.props;
    debug('componentDidMount', {props: this.props, isReady});

    const trackView = () => {
      const anonUserId = dispatch(getAnonUserId());

      if (state.isAppInPreviewMode === true) return;

      trackPageView(pageProps, anonUserId)
        .then(({eventIds}) => {
          dispatch(setSSPPageViewedEventId(eventIds[0].sspPageViewedEventId));
        })
        .catch(() => {
          dispatch(setSSPPageViewedEventId(''));
        });
    };

    if (!isReady) {
      return defer(() => trackView());
    }

    trackView();
  }

  // eslint-disable-next-line
  postReduxActions = ({store}) => {
    if (!isBrowser()) return null;

    const {accountId} = this.props.pageProps?.query?.appInfo ?? {};
    const gemId = this.props.pageProps.query.currentGem?._id;
    this.logPageView(store);

    return <DataDogLoggers accountId={accountId} gemId={gemId} />;
  };

  render() {
    const {Component, pageProps, show404, err, getPageAPIError, resError, statusCode, logEmitter} =
      this.props;
    const favicon = pageProps?.query?.appInfo?.settings?.images?.favicon;

    if (err || getPageAPIError || (statusCode && statusCode !== 200)) {
      let error;
      const meta = {
        statusCode,
        getPageAPIError,
        err,
        resError,
      };

      if (getPageAPIError) {
        const messagePrefix = 'getPage API:';

        if (statusCode === 404) {
          error = {message: `${messagePrefix} Library Not Found`, meta, logLevel: 'debug'};
        }
        // 403 - Not Published
        else if (statusCode === 403) {
          error = {message: `${messagePrefix} SSP Not Published`, meta, logLevel: 'debug'};
        }
        // AJV, Shelf's Bad Request HTTP
        else if (statusCode === 400) {
          error = {message: `${messagePrefix} 400th error`, meta, logLevel: 'warn'};
        }
        // Internal Server, AWS and Network errors
        else {
          error = {message: `${messagePrefix} Internal error`, meta, logLevel: 'error'};
        }
      } else if (err) {
        error = {message: 'Next.js error', meta, logLevel: 'error'};
      }
      // statusCode !== 200
      else {
        error = {message: 'Response Next.js error', meta, logLevel: 'error'};
      }

      logSSRError(error, logEmitter);

      const WomanWithGlasses = dynamic(() => import('../components/WomanWithGlasses'));

      return (
        <>
          <Head>
            <title>Not found - Shelf</title>
            <link
              rel="icon"
              href={`${staticCDNProvider()}/images/favicon/favicon.ico`}
              type="image/x-icon"
            />
          </Head>

          <WomanWithGlasses />
        </>
      );
    }

    return (
      <>
        <Head>
          <link
            rel="icon"
            href={favicon ?? `${staticCDNProvider()}/images/favicon/favicon.ico`}
            type="image/x-icon"
          />
        </Head>
        <ReactReduxContext.Consumer>{this.postReduxActions}</ReactReduxContext.Consumer>
        {show404 ? (
          <ErrorBoundaryApp>
            <ErrorPage {...pageProps} />
          </ErrorBoundaryApp>
        ) : (
          <ErrorBoundaryApp>
            <Component {...pageProps} />
          </ErrorBoundaryApp>
        )}
      </>
    );
  }
}

export default withError(wrapper.withRedux(MyApp));
