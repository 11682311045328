import React, {useContext} from 'react';
import {AppContext} from '../../lib/context';
import {useServerLanguage} from '../../lib/i18n';

const I18NT: React.FC<{t: string}> = ({t, ...rest}) => {
  const context = useContext(AppContext);
  const lang = context?.query?.appInfo?.settings?.language || 'en';

  const i18n = useServerLanguage(lang);

  if (!context) {
    return null;
  }

  return <>{i18n.t(t, {...rest})}</>;
};

export default I18NT;
