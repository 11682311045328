import type {ArticlePageCurrentGem} from '../types';
import type {ArticlePageRoutesData, GetDataForRouteArgs} from './types';
import {getCommonDataForGemPagesRoutes} from './common';
import {getBreadcrumbsForUI} from './utils';

function getDataForArticlePageRoute(args: GetDataForRouteArgs): ArticlePageRoutesData {
  const currentGem = args.query.currentGem as ArticlePageCurrentGem;

  const breadCrumbs = getBreadcrumbsForUI(currentGem.breadCrumbs || []);

  return {
    ...getCommonDataForGemPagesRoutes(args),
    breadCrumbs,
  } as ArticlePageRoutesData;
}

export default getDataForArticlePageRoute;
