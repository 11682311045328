import * as React from 'react';
import {escape} from 'lodash-es';

type IProps = {
  text: string;
};

export function HTMLComment(props: IProps) {
  return <span dangerouslySetInnerHTML={{__html: `<!-- ${escape(props.text)} -->`}} />;
}

export default HTMLComment;
