import {useEffect} from 'react';

export enum PlaceToInsert {
  bodyStart = 'bodyStart',
  bodyEnd = 'bodyEnd',
  head = 'head',
}

type ExternalScriptConfig = {
  src?: string;
  text?: string;
  async?: boolean;
  id: string;
  testId?: string;
  type?: string;
  defer?: boolean;
  placeToInsert: PlaceToInsert;
};

type ExternalLinkConfig = {
  href: string;
  rel: string;
  type: string;
  id: string;
  testId?: string;
  placeToInsert: PlaceToInsert;
  shouldPlaceOnBeginOfParent?: boolean;
};

export type ExternalLibsConfig = {
  scripts: ExternalScriptConfig[];
  links: ExternalLinkConfig[];
};

type Props = {
  config: ExternalLibsConfig;
};

const ExternalLibs = (props: Props) => {
  useEffect(() => {
    const {scripts = [], links = []} = props.config;

    links.forEach(addExternalLink);

    scripts.forEach(addExternalScript);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

function addExternalLink(externalLink: ExternalLinkConfig) {
  const isLinkRendered = Boolean(document.getElementById(externalLink.id));

  if (!isLinkRendered) {
    const link = document.createElement('link');

    if (externalLink.testId) {
      link.setAttribute('data-testid', externalLink.testId);
    }
    link.id = externalLink.id;
    link.rel = externalLink.rel;
    link.type = externalLink.type;
    link.href = externalLink.href;

    addElementToDOM(externalLink.placeToInsert, link);
  }
}

function addExternalScript(externalScript: ExternalScriptConfig) {
  const isScriptRendered = Boolean(document.getElementById(externalScript.id));

  if (!isScriptRendered) {
    const script = document.createElement('script');

    if (externalScript.testId) {
      script.setAttribute('data-testid', externalScript.testId);
    }
    script.id = externalScript.id;
    if (externalScript.src) {
      script.src = externalScript.src;
    }

    if (externalScript.text) {
      script.text = externalScript.text;
    }

    if (externalScript.type) {
      script.type = externalScript.type;
    }
    script.defer = Boolean(externalScript.defer);

    script.async = Boolean(externalScript.async);

    addElementToDOM(externalScript.placeToInsert, script);
  }
}

function addElementToDOM(placeToInsert: PlaceToInsert, child: HTMLElement) {
  if (placeToInsert === PlaceToInsert.head) {
    document.head.appendChild(child);
  } else {
    if (placeToInsert === PlaceToInsert.bodyEnd) {
      document.body.appendChild(child);
    } else {
      document.body.prepend(child);
    }
  }
}

export default ExternalLibs;
