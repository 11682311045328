import type {GetDataForRouteArgs, SearchPageRoutesData} from './types';
import {getCommonDataForNotGemPagesRoutes} from './common';

function getDataForSearchPageRoute(args: GetDataForRouteArgs): SearchPageRoutesData {
  const {query} = args;

  const {showArticleCategories} = query?.appInfo?.settings || {};

  return {
    ...getCommonDataForNotGemPagesRoutes(args),
    query: {
      ...query,
    },
    showArticleCategories,
  } as SearchPageRoutesData;
}

export default getDataForSearchPageRoute;
