export enum ReactionType {
  THANKS = 'THANKS',
  WAS_IT_HELPFUL = 'WAS_IT_HELPFUL',
}

export enum Emoji {
  thumbsup = 'thumbsup',
  grinning = 'grinning',
  pensive = 'pensive',
}

export const EMOJI_SIZE = 20;

export const EMOJI_HOVER_SIZE = 30;

export const ITEMS_PER_PAGE = 20;

export const SEARCH_QUERY_MAX_LENGTH = 256;

export const CATEGORIES_DELIMITER_UI = ' > ';

export const RERENDER_DELAY = 150;

export const UPVOTE = 'upvote';
export const DOWNVOTE = 'downvote';

export const TOTAL_GEMS_TREE_LIMIT = 20 * 1000;
