import cheerio from 'cheerio';

export const arrowIcon = `
  <span class="CollapsibleSection__icon" style="display: flex; flex-direction: column; justify-content: space-between;align-items: center;width: 8px;height: 8px;transition: transform 0.2s ease-out;">
    <span contenteditable="false" style="width: 10px;
      height: 2px;
      border-radius: 8px;
      background-color: black;
      transform: rotate(45deg);"></span>
    <span contenteditable="false" style="width: 10px;
      height: 2px;
      border-radius: 8px;
      background-color: black;
      transform: rotate(-45deg);"></span>
  </span>
`;

const CS_CLASS = 'CollapsibleSection';
const CS_EDIT_MODE_PART = `${CS_CLASS}__content--is-edit_mode`;
const CS_VIEW_MODE_PART = `${CS_CLASS}__content--is-view_mode`;
const CS_EDIT_IS_CLOSE_ACTIVE_PART = `${CS_CLASS}__close--active`;
const CS_EDIT_IS_ACTIVE_PART = `${CS_CLASS}--active`;
const CS_LABEL_PART = `${CS_CLASS}__label`;
const CS_ICON_PART = `${CS_CLASS}__icon`;
const CS_TOP_PART = `${CS_CLASS}__top`;
const CS_CLOSE_PART = `${CS_CLASS}__close`;
const DATA_LABEL = 'data-label';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const htmlWithCollapsibleSections = (wikiText: string) => {
  const includesSections =
    wikiText.includes('data-content') && wikiText.includes('contenteditable');

  if (!includesSections) {
    return wikiText;
  }

  const $ = cheerio.load(wikiText);

  $(`.${CS_CLOSE_PART}`).remove();

  $('[contenteditable=true]').each((_, el) => {
    $(el).removeAttr('contenteditable');
    $(el).attr('contenteditable-disabled', 'true');
  });

  $(`.${CS_CLASS} > .${CS_CLASS}__content`).each((_, el) => {
    $(el).removeClass(CS_EDIT_MODE_PART);
    $(el).addClass(CS_VIEW_MODE_PART);
  });

  $(`.${CS_CLASS} .${CS_EDIT_IS_CLOSE_ACTIVE_PART}`).each((_, el) => {
    $(el).removeClass(CS_EDIT_IS_CLOSE_ACTIVE_PART);
  });

  $(`.${CS_EDIT_IS_ACTIVE_PART}`).each((_, el) => {
    $(el).removeClass(CS_EDIT_IS_ACTIVE_PART);
  });

  // OLD C.Sections
  $('div[data-id][data-toggle]').each((_, el) => {
    const newInput = $('<input>');
    newInput.attr('type', 'checkbox');
    newInput.addClass('CollapsibleSection__toggle');

    const attributes = el.attribs;
    for (const attr in attributes) {
      if (attr === 'data-id') {
        // nosemgrep
        newInput.attr('id', attributes[attr]);
      } else {
        // nosemgrep
        newInput.attr(attr, attributes[attr]);
      }
    }

    $(el).replaceWith(newInput);
  });

  $(`.${CS_CLASS} > .${CS_TOP_PART}`).each((_, el) => {
    if (!$(el).find(`.${CS_LABEL_PART}`).length) {
      const dataCSId = $(el).parent().attr('data-cs-id') || '';
      const labelElement = $('<label>');
      labelElement.addClass(`${CS_LABEL_PART} ${CS_LABEL_PART}--view`);
      labelElement.attr(DATA_LABEL, '');
      labelElement.html(arrowIcon);
      labelElement.attr('for', dataCSId);

      $(el).prepend(labelElement);
    }

    $(el)
      .find(`.${CS_LABEL_PART}`)
      .each((_, labelEl) => {
        if (!$(labelEl).find(`.${CS_ICON_PART}`).length) {
          $(labelEl).html(arrowIcon);
        }
      });
  });

  if (!$('input[type="checkbox"]').length) {
    $('div[data-cs="true"]').each((_, el) => {
      const dataCSId = $(el).attr('data-cs-id') || '';

      const inputElement = $('<input>');
      inputElement.attr('type', 'checkbox');
      inputElement.addClass('CollapsibleSection__toggle');
      inputElement.attr('data-toggle', '');
      inputElement.css('display', 'none');
      inputElement.attr('id', dataCSId);
      $(el).prepend(inputElement);

      $(el)
        .find(`.${CS_LABEL_PART}`)
        .each((_, labelEl) => {
          $(labelEl).removeAttr('class');
          $(labelEl).removeAttr(DATA_LABEL);
          $(labelEl).attr('data-remove', 'true');

          const wrapperLabel = $('<label>');
          wrapperLabel.attr('for', dataCSId);
          wrapperLabel.addClass(`${CS_LABEL_PART} ${CS_LABEL_PART}--view`);
          wrapperLabel.attr(DATA_LABEL, '');

          while ($(labelEl).children().length) {
            wrapperLabel.append($(labelEl).children().first());
          }
          $(labelEl).before(wrapperLabel);
          wrapperLabel.append(labelEl);
        });

      $(el)
        .find('[data-remove="true"]')
        .each((_, removeEl) => {
          while ($(removeEl).children().length) {
            $(removeEl).before($(removeEl).children().first());
          }
          $(removeEl).remove();
        });
    });

    $(`.${CS_ICON_PART}`).each((_, iconEl) => {
      if (!$(iconEl).children().length) {
        $(iconEl).replaceWith(arrowIcon);
      }
    });
  }

  return $.html();
};
