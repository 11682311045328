import parse from 'html-react-parser';
import {gems as gemHelpers} from '@shelf/helpers';
import {log} from '@shelf/client-logger';
import type {
  BaseRoutesData,
  GemPageRoutesData,
  GetDataForRouteArgs,
  NotGemPageRoutesData,
} from './types';
import type {ArticleLinkWidget, FolderWidget, PageBreadCrumbs, Query} from '../types';
import {useServerLanguage as getServerLanguage, i18n as i18nInstance} from '../i18n';
import {SEARCH_QUERY_MAX_LENGTH} from '../constants';
import {getFoldersForWidget} from '../helpers/helpers';
import {slugToUrl} from '../helpers/linkHelpers';
import {isBrowser} from '../helpers/browserHelpers';
import {
  getDictionary,
  getFooterLinks,
  getPrefixAndLangParts,
  getRootItemsOfPageWIthUrls,
  getSocialLinks,
} from './utils';

// GEM pages are `home | folder | article`
export function getCommonDataForGemPagesRoutes(args: GetDataForRouteArgs): GemPageRoutesData {
  const {query} = args;

  if (isBrowser() && Boolean(query.currentGem) === false) {
    log.error('getCommonDataForGemPagesRoutes missing currentGem,', {
      libraryId: query?.appInfo?.libraryId,
      accountId: query?.appInfo?.accountId,
      url: query?.url,
    });
  }

  const {currentGem = {} as Query['currentGem'], pageType} = query;
  const {pathPrefix, lang} = getPrefixAndLangParts(args);
  const {gems = [], folders = []} = currentGem;

  const topArticles = getRootItemsOfPageWIthUrls(gems) as ArticleLinkWidget[];
  const topFolders = getRootItemsOfPageWIthUrls(folders) as FolderWidget[];

  const topFoldersWithChildren = getFoldersForWidget({
    folders: topFolders,
  });

  const target = currentGem;
  const pages = [...gems, ...folders];
  const excludeTitle = Boolean(query?.appInfo?.settings?.header?.excludeTitle);
  const showTargetTitle =
    target && !gemHelpers.isSelfServiceLibrary(target) && target?._id && !excludeTitle;

  return {
    ...getCommonDataForAllRoutes(args),
    query: {
      ...query,
    },
    excludeTitle,
    folder: currentGem,
    folders,
    gem: currentGem,
    gems,
    lang,
    pages,
    pageType,
    pathPrefix,
    showTargetTitle, // To show ot not Article title in the breadcrumbs SSPAPP-169
    target,
    topArticles,
    topFolders,
    topFoldersWithChildren,
  };
}

// Not gems pages are `search | 404`
export function getCommonDataForNotGemPagesRoutes(args: GetDataForRouteArgs): NotGemPageRoutesData {
  return {
    ...getCommonDataForAllRoutes(args),
    query: args.query,
  };
}

function getCommonDataForAllRoutes(args: GetDataForRouteArgs): BaseRoutesData {
  const {query} = args;

  const footerShelfLink = 'https://shelf.io';

  const footerCustomHTML = query.appInfo?.settings.footer?.customHTML
    ? parse(query.appInfo?.settings.footer?.customHTML)
    : null;
  const footerLinks = getFooterLinks(query.appInfo?.settings.footer?.customLinks || []);

  const {showMostViewedArticles} = query.appInfo.settings;
  const {pathPrefix, lang} = getPrefixAndLangParts(args);

  const i18n = getServerLanguage(lang);
  const dictionary = getDictionary(i18n, lang); // basically `@shelf/i18n-ssp` pckg

  const {showExtraSearch = true} = query.appInfo?.settings || {};

  const {breadCrumbs = []} = query.currentGem || {};

  const homeHrefFromBreadCrumbs = breadCrumbs[0]?.slug; // first item of breadcrumbs is the homepage

  const homeHref =
    homeHrefFromBreadCrumbs ||
    `${typeof window !== 'undefined' ? window.location.origin : ''}${slugToUrl(
      '/',
      pathPrefix,
      lang
    )}`;

  const searchInputPlaceholder = i18n.t('ssp-app.search.inputPlaceholder');
  const searchURL = slugToUrl('/search/', pathPrefix, lang);
  const socialLinks = getSocialLinks(query.appInfo?.settings.footer?.socialLinks || []);

  const shouldRemoveI18nResource = Boolean(
    query.templateDefinition.isPreviewed && query.templateDefinition?.i18nDefinition
  );

  // In the theme preview mode we want to re-add i18n ResourceBundle to apply latest localization changes
  if (shouldRemoveI18nResource) {
    i18nInstance.removeResourceBundle(lang, 'translation');
  }

  i18nInstance.addResourceBundle(
    lang,
    'translation',
    {
      i18nDefinition: query.templateDefinition?.i18nDefinition || {},
    },
    true,
    false
  );

  // TODO: refactor templates and remove these props from the search | 404 pages
  const breadCrumbsPlaceholder = [] as PageBreadCrumbs;
  const targetPlaceholder = {
    gems: [],
    folders: [],
  };

  return {
    appInfo: query.appInfo,
    breadCrumbs: breadCrumbsPlaceholder,
    defaultAvatarURL: `${process.env.STATIC_ASSETS}/images/backgrounds/avatar.svg`,
    defaultThumbnail: `${process.env.STATIC_ASSETS}/images/ssp-themes/shared/page-icon.svg`,
    dictionary,
    footerCustomHTML,
    footerLinks,
    footerShelfLink,
    headerColor: query?.appInfo?.settings?.header?.colors?.bg || 'rgb(0, 0, 70)',
    headerImage: query?.appInfo?.settings?.header?.image || '',
    headerTextColor: query?.appInfo?.settings?.header?.colors?.text,
    homeHref,
    homeLink: homeHref,
    lang,
    multiline: Boolean(query?.appInfo?.settings?.header?.multiline),
    pathByIds: breadCrumbsPlaceholder,
    pathPrefix,
    searchInputPlaceholder,
    searchQueryMaxLength: SEARCH_QUERY_MAX_LENGTH,
    searchURL,
    showExtraSearch,
    showMostViewedArticles,
    socialLinks,
    staticAssetsURL: process.env.STATIC_ASSETS as string,
    target: targetPlaceholder,
  };
}
