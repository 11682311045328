import cheerio from 'cheerio';
import {
  HIDDEN_ANCHOR_TEMPLATE,
  MINIMAL_TOC_STYLES,
  anchorizeHeaders,
  generateTOC as generateTOCFromHeaders,
} from '@shelf/toc';
import type {Header} from '@shelf/toc';

const TOC_ATTRIBUTE = 'data-toc';
const TOC_SELECTOR = `[data-toc='true']`;

export const setTOCIntoText = (
  contentText: string,
  {
    headerTemplate = HIDDEN_ANCHOR_TEMPLATE,
    attachMinimalTOCStyles = true,
    anchorPrefix,
  }: {
    headerTemplate?: string;
    attachMinimalTOCStyles?: boolean;
    anchorPrefix?: string;
  } = {}
): string => {
  const text = cheerio.load(contentText, null, false);

  if (!text(TOC_SELECTOR).length) {
    return contentText;
  }

  const {headers, html: contentTextAnchorized} = anchorizeHeaders(contentText, headerTemplate);

  const tocText = `${generateTOCFromHeaders(headers as Header[], anchorPrefix)}${
    attachMinimalTOCStyles ? MINIMAL_TOC_STYLES : ''
  }`;

  const textAnchorized = cheerio.load(contentTextAnchorized, null, false);

  const tocElements = textAnchorized(TOC_SELECTOR);

  tocElements.removeAttr(TOC_ATTRIBUTE);

  tocElements.html(tocText);

  return textAnchorized.html();
};
