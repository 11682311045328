import React from 'react';
import {b, createBlock} from '@shelf/bem';
import type {HTMLAttributes} from 'react';
import type {SearchAPIParams} from './useSearch';
import ColoredIcon from '../../components/ColoredIcon';
import {useServerLanguage} from '../../lib/i18n';
import {useAutosuggestData} from './useSearch';
import {Dropdown, IconHolder, Input, InputWrapper, Option, Wrapper} from './styled';

const block = createBlock('SearchAutoSuggest');

export type SearchAutoSuggestProps = {
  inputProps?: HTMLAttributes<HTMLInputElement>;
  children?: JSX.Element;
  clearIcon?: JSX.Element;
  placeholderPath?: string;
  searchFilters?: Partial<SearchAPIParams>;
};

const SearchAutoSuggestProvider = ({
  clearIcon,
  children,
  inputProps,
  placeholderPath,
  searchFilters,
}: SearchAutoSuggestProps) => {
  const {data, handleChange, handleClear, lang, term, isNoResults, isLoading} =
    useAutosuggestData(searchFilters);
  const i18n = useServerLanguage(lang);
  const renderDefaultSuggestions = () => (
    <Dropdown role={'listbox'} className={b(block, 'suggestions')}>
      {data?.items?.length
        ? data.items.map(({title}, i) => {
            return (
              <Option key={i} role={'option'} className={b(block, 'option')}>
                {title}
              </Option>
            );
          })
        : null}
    </Dropdown>
  );

  const renderClearIcon = () => {
    if (!term || clearIcon === null) {
      return null;
    }

    return (
      <IconHolder onClick={handleClear} className={b(block, 'clear-icon-holder')}>
        {clearIcon ? clearIcon : <ColoredIcon icon={'cross'} holderSize={30} iconSize={7} />}
      </IconHolder>
    );
  };

  const renderSuggestions = () => {
    if (!children) {
      return renderDefaultSuggestions();
    }

    return React.Children.map(children, (child, i) => {
      return React.cloneElement(child, {
        key: i,
        loading: isLoading,
        data: data?.items,
        term,
        isNoResults,
      });
    });
  };

  const getPlaceHolder = () => {
    if (!placeholderPath) return;

    // nosemgrep
    if (i18n.exists(placeholderPath)) {
      return i18n.t(placeholderPath) as string;
    }

    return placeholderPath;
  };

  return (
    <Wrapper className={b(block, 'wrapper')}>
      <InputWrapper className={b(block, 'input-wrapper')}>
        <Input
          className={b(block, 'input')}
          {...inputProps}
          placeholder={getPlaceHolder()}
          value={term}
          onChange={e => handleChange(e.target.value)}
          type="text"
        />
        {renderClearIcon()}
      </InputWrapper>
      {renderSuggestions()}
    </Wrapper>
  );
};

export default SearchAutoSuggestProvider;
