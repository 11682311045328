import {isEqual} from 'lodash-es';
import {stringifyQueryParams} from '@shelf/client-helpers';
import type {SSPApi} from '@shelf/data-hooks';
import type {LanguageCode} from '@shelf/types/lib/l10n';
import type i18next from '@shelf/i18n-ssp';
import type {
  APIBreadCrumbs,
  ArticleLinkWidget,
  FolderWidget,
  FooterSettings,
  PageBreadCrumbs,
  PageFolderItem,
  PageGemItem,
  Query,
} from '../types';
import type {GetDataForRouteArgs} from './types';
import {addProtocol, getPathPrefixFromDomain, omitSingleProp} from '../helpers/helpers';
import {slugToUrl} from '../helpers/linkHelpers';

type SSPPopularItem =
  ReturnType<typeof SSPApi.mostPopularTags> extends Promise<infer T> ? T : never;

export function getCustomDomainFromQuery(query?: Pick<Query, 'appInfo'>): string | false {
  return query?.appInfo?.settings?.customDomain || false;
}

export function getRootItemsOfPageWIthUrls(
  items: PageGemItem[] | PageFolderItem[]
): ArticleLinkWidget[] | FolderWidget[] {
  return items.map((item: PageGemItem | PageFolderItem) => {
    return {
      ...item,
      url: item.slug,
    };
  });
}

export function getPrefixAndLangParts(args: GetDataForRouteArgs): {
  pathPrefix: string;
  lang: LanguageCode;
} {
  const customDomain = getCustomDomainFromQuery(args.query);
  const pathPrefix = getPathPrefixFromDomain(customDomain);
  const lang = args.query?.appInfo?.settings?.language || 'en';

  return {pathPrefix, lang};
}

export function getDictionary(i18n: typeof i18next, lang: LanguageCode) {
  // basically `@shelf/i18n-ssp` pckg

  return i18n.getDataByLanguage(lang)?.translation &&
    !isEqual(omitSingleProp('i18nDefinition', i18n.getDataByLanguage(lang)?.translation || {}), {})
    ? i18n.getDataByLanguage(lang)?.translation
    : {
        ...i18n.getDataByLanguage('en')?.translation,
        i18nDefinition: i18n.getDataByLanguage(lang)?.translation.i18nDefinition,
      };
}
export function getTagsLinksForUI(
  tags: SSPPopularItem,
  {pathPrefix, lang}: {pathPrefix: string; lang: string}
) {
  return tags.map(chipWithCount => ({
    ...chipWithCount,
    url: `${slugToUrl('/search/', pathPrefix, lang)}?${stringifyQueryParams({
      tag: chipWithCount.name,
      page: 1,
    })}`,
  }));
}

export function getFooterLinks(settings: FooterSettings['customLinks']) {
  return settings.map(link => ({
    ...link,
    href: addProtocol(link.url),
  }));
}

export function getSocialLinks(settings: FooterSettings['socialLinks']): {
  href: string;
  altText: string;
}[] {
  return [
    {
      href: settings.facebook,
      altText: 'Facebook',
    },
    {
      href: settings.instagram,
      altText: 'Instagram',
    },
    {
      href: settings.linkedin,
      altText: 'Linkedin',
    },
    {
      href: settings.medium,
      altText: 'Medium',
    },
    {
      href: settings.twitter,
      altText: 'Twitter',
    },
    {
      href: settings.youtube,
      altText: 'Youtube',
    },
  ]
    .filter(item => item.href)
    .map(link => ({
      ...link,
      href: addProtocol(link.href),
    })) as {
    href: string;
    altText: string;
  }[];
}

export function getBreadcrumbsForUI(data: APIBreadCrumbs): PageBreadCrumbs {
  const mapToBreadcrumbsItem = (item: APIBreadCrumbs[0]) => ({
    ...item,
    href: item.slug,
  });

  // first item is always a home page. We do not want to have home page slug here as we pass it in the other place as `homeHref`
  return data.slice(1).map(mapToBreadcrumbsItem);
}
