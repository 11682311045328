export const noScrollClassName = 'noScroll';
export const handleBodyScrollChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  const noScroll = Boolean(event.target.checked);

  const getBodyClassNames = () => document.body.className;
  const setBodyClassNames = (classNames: string) => {
    document.body.className = classNames;
  };

  const bodyClassNames = `${getBodyClassNames().replace(noScrollClassName, '')} ${
    noScroll ? noScrollClassName : ''
  }`;

  setBodyClassNames(bodyClassNames);
};
